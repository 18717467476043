<template>
	<div>
		<h1>Manage</h1>
		<v-row v-for="(i, c) in items" :key="c">
			<v-col v-for="b in i" :key="b.title">
				<v-card>
					<v-card-title>
						<center>{{ b.title }}</center>
					</v-card-title>
					<v-card-text>
						<center>
							<v-icon size="96">
								{{ b.icon }}
							</v-icon>
						</center>
					</v-card-text>
					<v-card-actions>
						<v-spacer /><v-btn color="primary" :to="b.action.to">
							{{ b.action.title }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			allItems: [
				{
					title: "Billing",
					icon: "mdi-currency-usd-circle",
					action: {
						title: "Open",
						to: "/manage/billing",
					},
				},
				{
					title: "Types",
					icon: "mdi-list-status",
					action: {
						title: "Open",
						to: "/manage/types",
					},
				},
				{
					title: "Agents",
					icon: "mdi-account-group",
					action: {
						title: "Open",
						to: "/manage/agents",
					},
				},
			],
		};
	},
	computed: {
		items() {
			let x = [];
			let group = [];
			let len = this.allItems.length;
			for (let i = 0; i < len; i++) {
				const element = this.allItems[i];
				group.push(element);
				if (i + (1 % 3) == 0 || len - 1 == i) {
					x.push(JSON.parse(JSON.stringify(group)));
					group = [];
				}
			}
			return x;
		},
	},
};
</script>

<style></style>
